"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.localStorageService = void 0;
exports.localStorageService = {
    setItem: function (key, val) {
        var encoded = JSON.stringify(val);
        try {
            localStorage.setItem(key, encoded);
        }
        catch (e) {
            console.error("failed to write to local storage: ", e);
        }
    },
    getItem: function (key) {
        try {
            var val = localStorage.getItem(key);
            var decoded = JSON.parse(val);
            return decoded;
        }
        catch (e) {
            console.error("failed to read from local storage: ", e);
            return null;
        }
    },
};
