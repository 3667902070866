"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "styled", {
  enumerable: true,
  get: function get() {
    return _styled.styled;
  }
});
exports.build = exports.T = void 0;

var _microMemoize = _interopRequireDefault(require("micro-memoize"));

var _styleGetter = require("./styleGetter");

var _build2 = require("./build");

var _fastEquals = require("fast-equals");

var _constants = require("./constants/constants");

var _styled = require("./styled");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var state = {
  styles: (0, _build2.build)({})
};
var getStyles = (0, _styleGetter.getterFactory)(state);
var cacheConfig = {
  isEqual: _fastEquals.deepEqual,
  maxSize: _constants.MAX_CACHE_SIZE
};
var T = (0, _microMemoize["default"])(function (cls) {
  for (var _len = arguments.length, rest = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    rest[_key - 1] = arguments[_key];
  }

  return [].concat(_toConsumableArray(getStyles(cls)), rest);
}, cacheConfig);
exports.T = T;

var build = function build() {
  var userConfig = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  state.styles = (0, _build2.build)(userConfig);
};

exports.build = build;