"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _sizeByStyleMultiplier = _interopRequireDefault(require("./util/sizeByStyleMultiplier"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var sizes = {
  0: 0,
  '025': 0.025,
  '05': 0.05,
  '10': 0.1,
  '20': 0.2,
  '25': 0.25,
  '30': 0.3,
  '40': 0.4,
  '50': 0.5,
  '60': 0.6,
  '70': 0.7,
  '75': 0.75,
  '80': 0.8,
  '90': 0.9
};
var baseStyles = {
  'o-': ['opacity']
};

var _default = (0, _sizeByStyleMultiplier["default"])(sizes, baseStyles);

exports["default"] = _default;