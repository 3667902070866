"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Result = void 0;
var Result = /** @class */ (function () {
    function Result(ok, data, errMsg) {
        this.ok = ok;
        this.data = data;
        this.errMsg = errMsg;
    }
    Result.OK = function (data) {
        return new Result(true, data, undefined);
    };
    Result.ERR = function (msg) {
        return new Result(false, undefined, msg);
    };
    Result.reduce = function (results) {
        for (var i = 0; i < results.length; i += 1) {
            if (!results[i].ok) {
                return results[i];
            }
        }
        return results[results.length - 1];
    };
    return Result;
}());
exports.Result = Result;
