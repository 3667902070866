"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _sizeByStyleMultiplier = _interopRequireDefault(require("./util/sizeByStyleMultiplier"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var sizes = {
  '2/1': 2 / 1,
  '5/3': 5 / 3,
  '3/2': 3 / 2,
  '4/3': 4 / 3,
  '1': 1,
  '3/4': 3 / 4,
  '2/3': 2 / 3,
  '3/5': 3 / 5,
  '1/2': 1 / 2
};
var baseStyles = {
  'ar-': ['aspectRatio']
};

var _default = (0, _sizeByStyleMultiplier["default"])(sizes, baseStyles);

exports["default"] = _default;