"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// deletes keys when set to literal 'undefined'
function shallowMerge() {
    var objects = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        objects[_i] = arguments[_i];
    }
    var newObj = {};
    objects.forEach(function (o) {
        Object.keys(o).forEach(function (key) {
            if (o[key] === undefined) {
                delete newObj[key];
            }
            else {
                newObj[key] = o[key];
            }
        });
    });
    return newObj;
}
exports.default = shallowMerge;
