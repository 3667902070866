"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getterFactory = void 0;

var _invariant = _interopRequireDefault(require("invariant"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var SPLIT_VAL = ' ';
var colorKeys = {
  'bg-': 'backgroundColor',
  'b--': 'borderColor'
};

var isColor = function isColor(tachyon) {
  return ['#', 'rgb(', 'rgba('].some(function (text) {
    return tachyon.includes(text);
  });
};

var getColorStyle = function getColorStyle(tachyon) {
  return tachyon.startsWith('b') ? _defineProperty({}, colorKeys[tachyon.slice(0, 3)], tachyon.slice(3)) : {
    color: tachyon
  };
};

var getterFactory = function getterFactory(state) {
  return function (tachyonText) {
    (0, _invariant["default"])(state.styles, "Can't get styles until Build function is called");
    return tachyonText.split(SPLIT_VAL).reduce(function (arr, tachyon) {
      var key = tachyon.replace(/(\r\n|\n|\r)/gm, '');
      var style;
      if (!key) return arr;

      if (isColor(tachyon)) {
        style = getColorStyle(tachyon);
      } else {
        style = state.styles[key];
        (0, _invariant["default"])(style, "Unknown style key: ".concat(key, ". Check your spelling. If this is a custom style be sure to build it."));
      }

      return [].concat(_toConsumableArray(arr), [style]);
    }, []);
  };
};

exports.getterFactory = getterFactory;