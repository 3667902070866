"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var overflow = {
  ovv: {
    overflow: 'visible'
  },
  ovh: {
    overflow: 'hidden'
  },
  ovs: {
    overflow: 'scroll'
  }
};
var _default = overflow;
exports["default"] = _default;