"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _sizeByStyleMultiplier = _interopRequireDefault(require("./util/sizeByStyleMultiplier"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var flexBasisValues = {
  0: '0rem',
  1: '0.125rem',
  2: '0.25rem',
  3: '0.5rem',
  4: '0.75rem',
  5: '1rem',
  6: '2rem',
  7: '4rem',
  8: '8rem',
  9: '16rem',
  10: '32rem'
};
var flexGrowValues = {
  '1': 1,
  '2': 2,
  '3': 3,
  '4': 4,
  '5': 5,
  '6': 6,
  '7': 7,
  '8': 8,
  '9': 9,
  '10': 10,
  '11': 11,
  '12': 12
};
var flexBasis = {
  'flx-b-': ['flexBasis']
};
var flexGrow = {
  'flx-g-': ['flexGrow']
};

var _default = _objectSpread(_objectSpread({
  'flx-i': {
    flex: 1
  },
  'flx-row': {
    flexDirection: 'row'
  },
  'flx-row-reverse': {
    flexDirection: 'row-reverse'
  },
  'flx-col-reverse': {
    flexDirection: 'column-reverse'
  },
  'flx-wrap': {
    flexWrap: 'wrap'
  },
  aifs: {
    alignItems: 'flex-start'
  },
  aic: {
    alignItems: 'center'
  },
  aib: {
    alignItems: 'baseline'
  },
  aife: {
    alignItems: 'flex-end'
  },
  jcc: {
    justifyContent: 'center'
  },
  jcfs: {
    justifyContent: 'flex-start'
  },
  jcfe: {
    justifyContent: 'flex-end'
  },
  jcsb: {
    justifyContent: 'space-between'
  },
  jcsa: {
    justifyContent: 'space-around'
  },
  asfs: {
    alignSelf: 'flex-start'
  },
  asfe: {
    alignSelf: 'flex-end'
  },
  asc: {
    alignSelf: 'center'
  },
  ass: {
    alignSelf: 'stretch'
  },
  asb: {
    alignSelf: 'baseline'
  }
}, (0, _sizeByStyleMultiplier["default"])(flexBasisValues, flexBasis)), (0, _sizeByStyleMultiplier["default"])(flexGrowValues, flexGrow));

exports["default"] = _default;