"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _sizeByStyleMultiplier = _interopRequireDefault(require("./util/sizeByStyleMultiplier"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var fontSizes = {
  1: '0.875rem',
  2: '1rem',
  3: '1.25rem',
  4: '1.5rem',
  5: '2.25rem',
  6: '3rem'
};
var fontSizeStyles = {
  f: ['fontSize']
};
var fontWeightSizes = {
  1: '100',
  2: '200',
  3: '300',
  4: '400',
  5: '500',
  6: '600',
  7: '700',
  8: '800',
  9: '900'
};
var fontWeightStyles = {
  fw: ['fontWeight']
};

var _default = _objectSpread(_objectSpread(_objectSpread({}, (0, _sizeByStyleMultiplier["default"])(fontSizes, fontSizeStyles)), {}, {
  'f-headline': {
    fontSize: '6rem'
  },
  'f-subheadline': {
    fontSize: '5rem'
  },
  normal: {
    fontWeight: 'normal'
  },
  bold: {
    fontWeight: 'bold'
  },
  italic: {
    fontStyle: 'italic'
  }
}, (0, _sizeByStyleMultiplier["default"])(fontWeightSizes, fontWeightStyles)), {}, {
  tl: {
    textAlign: 'left'
  },
  tc: {
    textAlign: 'center'
  },
  tr: {
    textAlign: 'right'
  },
  tj: {
    textAlign: 'justify'
  },
  'lh-solid': {
    lineHeight: '1rem'
  },
  'lh-title': {
    lineHeight: '1.25rem'
  },
  'lh-copy': {
    lineHeight: '1.5rem'
  },
  tracked: {
    letterSpacing: '0.1rem'
  },
  'tracked-tight': {
    letterSpacing: '-0.05rem'
  },
  'tracked-mega': {
    letterSpacing: '0.25rem'
  },
  'no-underline': {
    textDecorationLine: 'none'
  },
  strike: {
    textDecorationLine: 'line-through'
  },
  underline: {
    textDecorationLine: 'underline'
  },
  'strike-underline': {
    textDecorationLine: 'underline line-through'
  },
  ttu: {
    textTransform: 'uppercase'
  },
  ttl: {
    textTransform: 'lowercase'
  },
  ttc: {
    textTransform: 'capitalize'
  }
});

exports["default"] = _default;