"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _sizeByStyleMultiplier = _interopRequireDefault(require("./util/sizeByStyleMultiplier"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var sizes = {
  0: '0rem',
  1: '0.125rem',
  2: '0.25rem',
  3: '0.5rem',
  4: '0.75rem',
  5: '1rem',
  6: '2rem',
  7: '4rem',
  8: '8rem',
  9: '16rem',
  10: '32rem'
};
var baseStyles = {
  br: ['borderRadius']
};

var _default = _objectSpread(_objectSpread({}, (0, _sizeByStyleMultiplier["default"])(sizes, baseStyles)), {}, {
  ba: {
    borderWidth: 1
  },
  bl: {
    borderLeftWidth: 1
  },
  br: {
    borderRightWidth: 1
  },
  bt: {
    borderTopWidth: 1
  },
  bb: {
    borderBottomWidth: 1
  },
  'bs-dashed': {
    borderStyle: 'dashed'
  },
  'bs-dotted': {
    borderStyle: 'dotted'
  },
  'bs-solid': {
    borderStyle: 'solid'
  }
});

exports["default"] = _default;