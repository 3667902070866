"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _flow = _interopRequireDefault(require("lodash/flow"));

var _flowRight = _interopRequireDefault(require("lodash/flowRight"));

var _fromPairs = _interopRequireDefault(require("lodash/fromPairs"));

var _toPairs = _interopRequireDefault(require("lodash/toPairs"));

var _map = _interopRequireDefault(require("lodash/fp/map"));

var _flatMap = _interopRequireDefault(require("lodash/fp/flatMap"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

var forEachBaseStyle = function forEachBaseStyle(styleFn) {
  return (0, _flow["default"])(_toPairs["default"], (0, _flatMap["default"])(styleFn), _fromPairs["default"]);
};

var forEachSize = function forEachSize(sizes) {
  return function (styles) {
    return (0, _flow["default"])(_toPairs["default"], (0, _map["default"])(applySizeForStyle(styles)))(sizes);
  };
};

var applySizeForStyle = function applySizeForStyle(_ref) {
  var _ref2 = _slicedToArray(_ref, 2),
      styleKey = _ref2[0],
      styleProperties = _ref2[1];

  return function (_ref3) {
    var _ref4 = _slicedToArray(_ref3, 2),
        sizeKey = _ref4[0],
        size = _ref4[1];

    return ["".concat(styleKey).concat(sizeKey), applySizeForStyleProperty(styleProperties)(size)];
  };
};

var applySizeForStyleProperty = function applySizeForStyleProperty(styleProperties) {
  return function (size) {
    return (0, _flow["default"])((0, _map["default"])(function (styleProperty) {
      return [[styleProperty], size];
    }), _fromPairs["default"])(styleProperties);
  };
};

var SizeToStylesMultiplier = (0, _flowRight["default"])(forEachBaseStyle, forEachSize);

var _default = function _default(sizes, styles) {
  return SizeToStylesMultiplier(sizes)(styles);
};

exports["default"] = _default;