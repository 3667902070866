"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createAndFormatStyles = createAndFormatStyles;
exports.build = build;

var _generateColorPalette = _interopRequireDefault(require("./builders/generateColorPalette"));

var _scaleStylesForRem = _interopRequireDefault(require("./builders/scaleStylesForRem"));

var _flexBox = _interopRequireDefault(require("./styles/flexBox"));

var _spacing = _interopRequireDefault(require("./styles/spacing"));

var _images = _interopRequireDefault(require("./styles/images"));

var _opacity = _interopRequireDefault(require("./styles/opacity"));

var _absolute = _interopRequireDefault(require("./styles/absolute"));

var _heightAndWidth = _interopRequireDefault(require("./styles/heightAndWidth"));

var _borders = _interopRequireDefault(require("./styles/borders"));

var _overflow = _interopRequireDefault(require("./styles/overflow"));

var _aspectRatio = _interopRequireDefault(require("./styles/aspectRatio"));

var _text = _interopRequireDefault(require("./styles/text"));

var _merge = _interopRequireDefault(require("lodash/merge"));

var _constants = require("./constants/constants");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var DEFAULT_CONFIG = {
  rem: _constants.DEFAULT_REM_SIZE,
  colors: {},
  styles: {}
};

var formatStyles = function formatStyles(_ref) {
  var rem = _ref.rem;
  return (0, _scaleStylesForRem["default"])(rem);
};

var createStyles = function createStyles(config) {
  return _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, _spacing["default"]), _images["default"]), _opacity["default"]), _flexBox["default"]), _absolute["default"]), _heightAndWidth["default"]), _borders["default"]), _overflow["default"]), _aspectRatio["default"]), _text["default"]), (0, _generateColorPalette["default"])(config.colors)), config.styles);
};

function createAndFormatStyles(config) {
  return formatStyles(config)(createStyles(config));
}

function build(userConfig) {
  var mergedConfig = (0, _merge["default"])(DEFAULT_CONFIG, userConfig);
  return createAndFormatStyles(mergedConfig);
}