"use strict";
/*
  A fairly simple store.
  The gist:
   - We maintain a plain JS object of state.
   - We can update the state with the 'update' function,
     from which we return the updated state.
   - We can register change callbacks with 'subscribe'.
     Subscriptions have two parts: a 'selector' and a 'subscriber'.
     'selector' is a function of the state that returns a value.
     When it's output changes, we call the subscriber with this
     changed value.  The subscriber is also called once initially.
*/
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createStore = exports.Store = void 0;
var vanillaEq = function (a, b) { return a === b; };
var Store = /** @class */ (function () {
    function Store(initial) {
        var _this = this;
        this.changeSubscribers = {};
        this.update = function (updater) {
            _this.current = updater(_this.current);
            Object.values(_this.changeSubscribers).forEach(function (cb) { return cb(_this.current); });
        };
        this.subscribe = function (
        // selector could also be a Paths<T>,
        // but this lets us cache more complex logic than just key lookup
        selector, subscriber, comparitor) {
            if (comparitor === void 0) { comparitor = vanillaEq; }
            var id = Date.now().toString()
                + Math.random().toString().slice(2, 7);
            var lastVal = selector(_this.current);
            _this.changeSubscribers[id] = function (newState) {
                var newVal = selector(newState);
                if (!comparitor(lastVal, newVal)) {
                    lastVal = newVal;
                    subscriber(newVal);
                }
            };
            // first call
            subscriber(lastVal);
            return function () {
                delete _this.changeSubscribers[id];
            };
        };
        // convenience methods for updating
        this.shallowSet = function (key, val) {
            _this.update(function (state) {
                var _a;
                return (__assign(__assign({}, state), (_a = {}, _a[key] = val, _a)));
            });
        };
        this.current = initial;
    }
    Object.defineProperty(Store.prototype, "state", {
        get: function () {
            return this.current;
        },
        enumerable: false,
        configurable: true
    });
    return Store;
}());
exports.Store = Store;
var createStore = function (initial) { return new Store(initial); };
exports.createStore = createStore;
