"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var imageStyles = {
  'rm-contain': {
    resizeMode: 'contain'
  },
  'rm-cover': {
    resizeMode: 'cover'
  },
  'rm-stretch': {
    resizeMode: 'stretch'
  }
};
var _default = imageStyles;
exports["default"] = _default;